import { useTranslation } from 'i18n'
import React, { useState, useEffect } from 'react'

import SText from './SText'

interface Props {
    children?: React.ReactNode
    forElementId: string
}

const SInputError: React.FC<Props> = (props: Props) => {
    const { children, forElementId } = props
    const [forElement, setForElement] = useState<HTMLElement | null>(null)
    const [coords, setCoords] = useState<{
        left: number
        right: number
        width: number
        top: number
    } | null>(null)

    const { t } = useTranslation()

    useEffect(() => {
        if (forElementId) {
            const ele = document.getElementById(forElementId)
            setForElement(ele)
            setCoords({
                left: ele?.offsetLeft,
                right: ele?.offsetLeft + ele?.offsetWidth,
                width: ele?.offsetWidth,
                top: ele?.offsetTop + ele?.offsetHeight,
            })
            const onResize = () => {
                setCoords({
                    left: ele?.offsetLeft,
                    right: ele?.offsetLeft + ele?.offsetWidth,
                    width: ele?.offsetWidth,
                    top: ele?.offsetTop + ele?.offsetHeight,
                })
            }
            window.addEventListener('resize', onResize)
            return () => {
                window.removeEventListener('resize', onResize)
            }
        }
    }, [forElementId, t, props.children])

    if (!forElement) return null

    return (
        coords && (
            <SText
                color={'inputError'}
                fontSize={10}
                sx={{
                    position: 'absolute',
                    left: coords.left,
                    right: coords.right,
                    width: coords.width,
                    top: coords.top,
                    marginTop: 2,
                }}
            >
                {children}
            </SText>
        )
    )
}

export default SInputError
