import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BoxProps, Button } from 'rebass'
import SBox from 'components/opinionless/SBox'
import { s18n } from '../i18n'
import { sTextHoverColor } from '../styles/helpers'
import { SStyled } from 'styles/theme'
import SText from './opinionless/SText'
import { AuthContext } from 'utils/authContext'
import * as moment from 'moment'
import 'moment/locale/es'

interface Props extends BoxProps {
    isAuthed: boolean
    switcherId: string
}

const Container = SStyled(SBox)<{ isAuthed: boolean }>`
    right: 40px;
    top: 40px;
    background-color: ${(p) => (p.isAuthed ? p.theme.colors.white : '#E1E0FF')};
    border-radius: ${(p) => p.theme.radii[0]}px;
    overflow: hidden;
    z-index: 10;
`

const RelativeContainer = SStyled(SBox)`
    position: relative;
`

const HoverBox = SStyled(SBox)<{ left: number; width: number }>`
    background-color: ${(p) => '#9490FF'};
    position: absolute;
    width: ${(p) => p.width}px;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(${(p) => p.left}px);
    z-index: 1;
    border-radius: ${(p) => p.theme.radii[0]}px;
    transition: transform 0.1s ease-out;
    box-shadow: ${(p) => p.theme.shadows.languageSwitcher};
`

const LanguageSwitcher: React.FC<Props> = (props: Props) => {
    const { isAuthed, switcherId, ...boxProps } = props
    const authContext = useContext(AuthContext)

    const switchToLanguage = useCallback(
        (language: string) => {
            s18n.changeLanguage(language)
            moment.locale(language)
        },
        [authContext.user]
    )

    const [target, setTarget] = useState(null)

    const languages = [
        {
            text: 'English',
            code: 'en',
        },
        {
            code: 'es',
            text: 'Español',
        },
    ]

    const selectedIndex = languages.findIndex((val) => val.code === s18n.language)

    useEffect(() => {
        const target = document.getElementById(`${switcherId}-languageSwitcherButton-${s18n.language}`)
        setTarget(target)

        moment.locale(s18n.language)
    }, [s18n.language])

    return (
        <Container isAuthed={isAuthed} {...boxProps}>
            <RelativeContainer>
                {languages.map((language) => (
                    <React.Fragment key={language.code}>
                        <LanguageSwitcherButton
                            switcherId={switcherId}
                            language={language}
                            isCurrentLanguage={language.code === s18n.language}
                            onClick={switchToLanguage}
                            isAuthed={isAuthed}
                        />
                    </React.Fragment>
                ))}
                {target && <HoverBox left={target.offsetLeft} width={target.offsetWidth} />}
            </RelativeContainer>
        </Container>
    )
}

interface LanguageSwitcherButtonProps {
    language: {
        code: string
        text: string
    }
    isCurrentLanguage: boolean
    onClick: (language: string) => void
    isAuthed: boolean
    switcherId: string
}
const LanguageSwitcherButton = (props: LanguageSwitcherButtonProps) => {
    const { language, isCurrentLanguage, switcherId, onClick: onClickProp, isAuthed } = props

    const onClick = useCallback((e) => {
        e.preventDefault();
        onClickProp(language.code)
    }, [language, onClickProp])

    let color = isCurrentLanguage ? 'white' : '#9490FF'

    return (
        <Button
            className="notranslate"
            id={`${switcherId}-languageSwitcherButton-${language.code}`}
            sx={{
                color: isCurrentLanguage ? 'black' : 'gray',
                fontWeight: isCurrentLanguage ? 'normal' : 'medium',
                zIndex: 2,
                position: 'relative',
                ...(!isCurrentLanguage ? sTextHoverColor('lightGray') : {}),
            }}
            pl={4}
            pr={4}
            pt={4}
            pb={4}
            onClick={onClick}
        >
            <SText fontSize={1} fontWeight={'bold'} color={color}>
                {props.language.text}
            </SText>
        </Button>
    )
}

export default LanguageSwitcher
