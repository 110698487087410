import React from 'react'
import LanguageSwitcher from "./LanguageSwitcher";
import SFlex from "./opinionless/SFlex";
import {StyledPatternImage} from "../styles/styledImage";
import {Container} from "next/app";
import SBox from "./opinionless/SBox";


const NavBar = () => {
    return <SBox sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} px={6} py={5}>
        <SFlex sx={{justifyContent: 'flex-end', alignItems: 'center', textAlign: "end"}}>
            <StyledPatternImage style={{cursor: 'pointer'}}
                                onClick={() => { window.location.href = 'http://f4gi.org/' }}
                                sx={{height: '40px'}} src='https://storage.googleapis.com/portal-web-resources/Pretrial/F4GI_Full%20Logo_Purple.png'/>
        </SFlex>
        <SFlex sx={{justifyContent: 'flex-end', alignItems: 'center', textAlign: "end"}}>
            <LanguageSwitcher
                switcherId="navbar"
                isAuthed={false}
            />
        </SFlex>
    </SBox>
}

export default NavBar
