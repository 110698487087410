import { default as PhoneInput } from 'react-phone-number-input/input'
import { SStyled } from './theme'

const height = 45

const StyledPhoneInput = SStyled(PhoneInput)`
    height: ${height}px;
    width: 100%;
    border-radius: ${(p) => p.theme.radii[1]}px;
    padding-left: ${(p) => p.theme.space[8]}px;
    padding-right: ${(p) => p.theme.space[3]}px;
    font-size: ${(p) => p.theme.fontSizes[3]}px;
    background-color: ${(p) => p.theme.colors.inputBg};
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    border-color: ${(p) => p.theme.colors.inputBorder};
    font-family: ${(p) => p.theme.fonts.default};
    color: ${(p) => p.theme.colors.black};
    -webkit-appearance: none;

    ::placeholder {
        color: ${(p) => p.theme.colors.inputPlaceholder};
    }

    &:hover {
        border-color: ${(p) => p.theme.colors.inputBorder};
    }

    &:focus {
        box-shadow: ${(p) => `0px 0px 0px 3px ${p.theme.colors.inputShadow}`};
        border-color: ${(p) => p.theme.colors.purple300};
        border-width: 1px;
    }

    outline: none;
`

const PlusOne = SStyled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    line-height: ${height}px;
    left: ${(p) => p.theme.space[4]}px;
    font-size: ${(p) => p.theme.fontSizes[3]}px;

`

export const PhoneNumberStyles = {
    StyledPhoneInput,
    PlusOne,
}
