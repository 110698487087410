import React, { useState } from "react";

import { SStyled } from "styles/theme";
import SBox from "./SBox";
import SInputLabel from "./SInputLabel";

interface Props {
  children?: React.ReactNode;
  content: any;
  sx?: any;
}

const TooltipContent = SStyled(SInputLabel)`
    background: white;
    margin: auto;
    border: 1px solid #A0A0A0;
    border-radius: 10px;
    padding: 5px;

    @media screen and (max-width: ${(p) => p.theme.breakpoints[0]}) {
      min-width: 200px;
    }
`;

const TooltipWrapper = SStyled(SBox)`

    
    @media screen and (max-width: ${(p) => p.theme.breakpoints[0]}) {
        width: 35%;
    }
`;

const STooltip: React.FC<Props> = (props: Props) => {
  let timeout;
  const [active, setActive] = useState(false);
  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };
  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  return (
    <TooltipWrapper
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      sx={{
        position: "relative",
        display: "flex !important",
        flexDirection: "row !important",
        height: "16px",
        ...props.sx,
      }}
    >
      <img
        style={{ textAlign: "center", marginRight: "10px" }}
        src="/tooltip-q-mark.svg"
      />
      {props.children}
      {active && (
        <TooltipContent
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "fit-content",
            minWidth: "350px",
            height: "fit-content",
            whiteSpace: "pre-wrap",
          }}
          className={`Tooltip`}
          style={{ zIndex: 100 }}
        >
          {props.content}
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};

export default STooltip;
