import React from 'react'
import { Input, InputProps } from '@rebass/forms'
import { useSTheme } from 'styles/theme'

type Props = InputProps

const SInput = React.forwardRef((props: Props, ref) => {
    const { sx = {}, ...otherProps } = props
    const theme = useSTheme()

    return (
        <Input
            sx={{
                bg: 'inputBg',
                borderRadius: 1,
                px: 5,
                py: 4,
                fontSize: 3,
                border: 'none',
                boxSizing: 'border-box',
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'inputBorder',
                // height: 46,
                fontFamily: theme.fonts.default,
                color: 'black',

                '::placeholder': {
                    color: 'inputPlaceholder',
                },
                '&:hover': {
                    borderColor: 'inputBorder',
                },

                '&:focus': {
                    boxShadow: `0px 0px 0px 3px ${theme.colors.inputShadow}`,
                    borderColor: 'purple300',
                    borderWidth: 1,
                },
                outline: 'none',

                ...sx,
            }}
            ref={ref}
            {...otherProps}
        />
    )
})

SInput.displayName = 'SInput'

export default SInput
