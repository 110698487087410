import STheme from "styles/theme";

const selectHeight = 46;

const sSelectStyle = (theme: typeof STheme) => ({
  control: (provided, state) => ({
    ...provided,
    borderRadius: theme.radii[1],
    borderWidth: 1,
    borderStyle: "solid",
    minHeight: selectHeight,
    boxShadow: "none",
    borderColor: theme.colors.gray200,
    fontSize: theme.fontSizes[3],
    paddingLeft: theme.space[3],
    paddingRight: theme.space[3],
    cursor: "pointer",
    ...(state.isFocused && {
      boxShadow: `0px 0px 0px 3px ${theme.colors.inputShadow}`,
      borderColor: `${theme.colors.inputBorder} !important`,
      borderWidth: 1,
    }),
    // border: 'none',
    // backgroundColor: state.isFocused ? theme.colors.darkerGray : theme.colors.darkGray,
  }),
  container: (provided, state) => ({
    ...provided,
    minHeight: selectHeight,
    cursor: "pointer",
  }),
  clearIndicator: (provided, state) => {
    return {
      ...provided,
      // color: state.isFocused ? theme.colors.white : theme.colors.lightGray,
      // '&:hover': {
      //     color: theme.colors.lightGray,
      // },
    };
  },
  menu: (provided, state) => ({
    ...provided,
    // backgroundColor: 'transparent',
    zIndex: 10,
    // boxShadow: 'none'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: theme.colors.gray300,
    whiteSpace: "nowrap",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    // backgroundColor: theme.colors.overlayBlack,
    // color: theme.colors.white,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    // color: theme.colors.white,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    marginHorizontal: 0,
    // color: theme.colors.white,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    // color: theme.colors.white,
  }),
  input: (provided, state) => ({
    ...provided,
    // color: theme.colors.white,
    // fontSize: theme.fontSizes[3],
    border: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    borderRadius: 6,

    // backgroundColor: theme.colors.darkGray,
  }),
  option: (provided, state) => {
    const isFocused = {
      backgroundColor: theme.colors.inputBg,
      color: theme.colors.black,
    };
    const isDisabled = {
      color: theme.colors.gray300,
      cursor: "not-allowed",
      fontStyle: "italic",
    };
    return {
      ...provided,
      // '&:active': {
      //     backgroundColor: 'green',
      // },
      backgroundColor: "transparent",
      color: theme.colors.black,
      // color: theme.colors.white,
      fontSize: theme.fontSizes[3],
      fontWeight: theme.fontWeights.regular,
      cursor: "pointer",
      ...(state.isFocused ? isFocused : {}),
      ...(state.isDisabled ? isDisabled : {}),
    };
  },
  dropdownIndicator: (provided, state) => {
    let color = null;
    const hoverColor = theme.colors.white;
    if (state.isFocused) {
      color = theme.colors.white;
    } else {
      color = theme.colors.gray100;
    }

    return {
      ...provided,
      // color,
      "&:hover": {
        // color: hoverColor,
      },
    };
  },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    // backgroundColor: theme.colors.lightGray,
  }),
});

export default sSelectStyle;
