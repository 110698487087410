import React, { useCallback, useMemo, useState } from 'react'

import { SStyled, useSTheme } from 'styles/theme'
import SBox from 'components/opinionless/SBox'
import { BoxProps } from 'rebass'

import LanguageSwitcher from 'components/LanguageSwitcher'

import { useTranslation } from 'i18n'
import ConditionalRender from "../../utils/conditionalRender";
import SFlex from "./SFlex";
import NavBar from "../NavBar";

export enum SPageType {
    Authorized = 'Authorized',
    Unauthorized = 'Unauthorized',
    UnauthorizedHelp = 'UnauthorizedHelp',
    NotFound = 'NotFound',
}

interface Props extends BoxProps {
    children?: React.ReactNode
    pageType: SPageType
}

const Container = SStyled(SBox)`
    flex: 1 1 auto;
    min-height: 100%;
    width: 100%;
    align-items: stretch;
    display: flex;
    flex-direction: row;
`

const Content = SStyled(SBox)`
    flex: 1 1 0;
    width: 100%;
    /* min-height: fit-content; */
    display: flex;
    flex-direction: column;
`

// interface SideBarContainerProps extends BoxProps {
//     showing: boolean
// }
// const SideBarContainer = SStyled(SBox)`
//     flex: 1 0 auto;
//     width: 100%;
//     display: flex;
//     flex-direction: row;
// `

const SPage: React.FC<Props> = (props: Props) => {
    const { pageType, children, sx = {}, ...boxProps } = props
    const theme = useSTheme()
    const { t } = useTranslation()

    const [sideBarIsOpen, setSideBarIsOpen] = useState(false)

    const toggleSideBarIsOpen = useCallback(() => {
        setSideBarIsOpen(!sideBarIsOpen)
    }, [sideBarIsOpen, setSideBarIsOpen])

    const contentBg = useMemo(() => {
        if (pageType === SPageType.Authorized || pageType === SPageType.UnauthorizedHelp) {
            return 'gray100'
        } else if (pageType === SPageType.Unauthorized) {
            return 'white'
        } else {
            return null
        }
    }, [pageType])

    const px = useMemo(() => {
        if (pageType === SPageType.Authorized || pageType === SPageType.UnauthorizedHelp) {
            return [0, 8, '80px']
        } else {
            return [6, 8, '80px']
        }
    }, [pageType])

    const py = useMemo(() => {
        if (pageType === SPageType.Authorized || pageType === SPageType.UnauthorizedHelp) {
            return [0, 9, '90px']
        } else {
            return [8, 9, '90px']
        }
    }, [pageType])

    return (
        <Container>
            <Content>
                <NavBar/>
                <SBox
                    sx={{
                        maxWidth: '720px',
                        margin: '10px auto',
                        flex: '1 1 0',
                        bg: contentBg,
                        minHeight: 'fit-content',
                        ...sx,
                    }}
                    px={6}
                    py={6}
                    {...boxProps}
                >
                    {children}
                </SBox>
            </Content>
        </Container>
    )
}

export default SPage
