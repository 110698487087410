import SInput from "components/opinionless/SInput";
import SInputLabel from "components/opinionless/SInputLabel";
import { PhoneNumberStyles } from "styles/phoneNumber";
import { SStyled } from "../../styles/theme";
import SText from "components/opinionless/SText";
import SFlex from "components/opinionless/SFlex";
import SBox from "../../components/opinionless/SBox";
import STextHyperlink from "components/opinionless/STextHyperlink";

export const StyledSFlex = SStyled(SFlex)`
    ${(p) => `
        @media screen and (max-width: ${p.theme.breakpoints[0]}) {
          flex-direction: column;
        }
    `}
`;

export const StyledSInputLabel = SStyled(SInputLabel)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      margin-top: 10px;
      font-size: 23px;
      min-width: 300px
    }
`}
`;

export const StyledSBox = SStyled(SBox)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      margin-bottom: 20px;
    }
`}
`;

export const StyledSTextHeader = SStyled(SText)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      font-size: 23px;
      margin-bottom: 10px;
    }
`}
`;

export const StyledSTextSignUp = SStyled(SText)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      font-size: 23px;
    }
`}
`;
export const StyledSTextDescription = SStyled(SText)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      font-size: 13px;
    }
`}
`;

export const StyledSInput = SStyled(SInput)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      font-size: 20px;
      padding-top: 9px;
      padding-bottom:9px;
    }
`}
`;

export const StyledSTextButton = SStyled(SText)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      font-size: 20px;
    }
`}
`;

export const StyledPhoneNumber = SStyled(PhoneNumberStyles.StyledPhoneInput)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      font-size: 20px;
    }
`}
`;

export const StyledPhoneNumberPlusOne = SStyled(PhoneNumberStyles.PlusOne)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      font-size: 20px;
    }
`}
`;


export const StyledSTextHyperlink = SStyled(STextHyperlink)`
${(p) => `
    @media screen and (max-width: ${p.theme.breakpoints[0]}) {
      color: #A0a0a0;
      text-decoration: underline;
    }
`}
`;
