import SButton from "components/opinionless/SButton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { s18n, useTranslation } from "i18n";
import { StepWizardChildProps } from "react-step-wizard";
import SText from "components/opinionless/SText";
import SFlex from "components/opinionless/SFlex";
import sSelectStyle from "styles/select";
import SPage, { SPageType } from "../../components/opinionless/SPage";
import { Controller, useForm } from "react-hook-form";
import SBox from "../../components/opinionless/SBox";
import Select from "react-select";
import {
useCheckTwoFactorAuthMutation,
  useCreateAppointmentMutation,
  useDefIdsLazyQuery,
} from "../../types/graphql";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { useSTheme } from "../../styles/theme";
import SInputError from "../../components/opinionless/SInputError";
import { Checkbox, Label } from "@rebass/forms";
import { Link } from "rebass";
import ConditionalRender from "utils/conditionalRender";
import SConfirmModal from "components/opinionless/SConfirmModal";
import { useOnClickOutside } from "utils/customHooks";
import STooltip from "components/opinionless/STooltip";
import ReactCodeInput from "react-code-input";
import {
  StyledSTextHeader,
  StyledSTextDescription,
  StyledPhoneNumberPlusOne,
  StyledSFlex,
  StyledPhoneNumber,
  StyledSInputLabel,
  StyledSInput,
  StyledSBox,
  StyledSTextSignUp,
  StyledSTextButton,
} from "./styles";
import STextHyperlink from "components/opinionless/STextHyperlink";

interface Props {
  email: string | null;
  inviteCode: string | null;
}

const SignUpForm: React.FC<Props> = (props: Props & StepWizardChildProps) => {
  const { t } = useTranslation("common");
  const [
    createAppointment,
    { data, loading, error },
  ] = useCreateAppointmentMutation();
  const [
    checkTwoFactor,
    { loading: twoFactorCheckLoading },
  ] = useCheckTwoFactorAuthMutation();
  const theme = useSTheme();

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [additionalPhoneNumberError, setAdditionalPhoneNumberError] = useState(
    false
  );
  const [isTwoFactorModalOpen, setIsTwoFactorModalOpen] = useState(false);
  const [selectedDefendant, setSelectedDefendant] = useState(null);
  const [
    isDefendantSelectorModalOpen,
    setIsDefendantSelectorModalOpen,
  ] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [pin, setPin] = useState("");
  const [twoFactorError, setTwoFactorError] = useState("");
  const [defendantIdError, setDefendantIdError] = useState("");
  const ref = useRef();
  const [
    caseNumberDefIdsQuery,
    {
      data: caseNumberDefIdsQueryData,
      loading: caseNumberDefIdsQueryLoading,
      error: caseNumberDefIdsQueryError,
    },
  ] = useDefIdsLazyQuery({ fetchPolicy: "no-cache" });

  useOnClickOutside(ref, () => {
    setIsDefendantSelectorModalOpen(false);
    setIsTwoFactorModalOpen(false);
    setIsModalOpen(false);
    setTwoFactorError("");
    setDefendantIdError("");
    setSelectedDefendant(null);
  });

  const inputStyleProp = {
    inputStyle: {
      fontFamily: "monospace",
      margin: "4px",
      marginTop: "8px",
      width: "15px",
      borderRadius: "3px",
      fontSize: "16px",
      height: "26px",
      paddingLeft: "7px",
      color: "#9490FF",
      border: "1px solid #9490FF",
    },
  };

  const {
    register,
    control,
    handleSubmit,
    getValues,
    errors: localErrors,
  } = useForm();

  const phoneNumberUnique = () => {
    const phoneNumber = getValues("phoneNumber");
    const phoneNumber2 = getValues("phoneNumber2");
    const phoneNumber3 = getValues("phoneNumber3");
    if (
      phoneNumber === phoneNumber2 ||
      phoneNumber === phoneNumber3 ||
      (phoneNumber2 && phoneNumber3 && phoneNumber2 === phoneNumber3)
    ) {
      return "additional_phone_number_similar_error";
    } else {
      return true;
    }
  };

  const caseNumberLength = () => {
    const caseNumber = getValues("caseNumber");

    if (caseNumber.split(" ").join("").length < 8) {
      return "case_number_length";
    } else {
      return true;
    }
  };

  const getDefendantsIds = (caseNumber: string) => {
    caseNumberDefIdsQuery({
      variables: { caseNumber: caseNumber },
    });
  };
  const createUserAndAppointment = (values, defId) => {
    createAppointment({
      variables: {
        language: s18n.language,
        name: values.firstName,
        phoneNumber: values.phoneNumber,
        phoneNumber2: values.phoneNumber2,
        phoneNumber3: values.phoneNumber3,
        caseNumber: values.caseNumber,
        defId: defId,
      },
    })
      .then((response) => {
        if (response.data.create.success) {
          setIsTwoFactorModalOpen(true);
          setModalTitle(t("one_more_step"));
          setModalText(t("two_factor_modal_text"));
          if (additionalPhoneNumberError) {
            setAdditionalPhoneNumberError(false);
          }
        } else {
          const alreadySubscribed = error.message
            .toString()
            .toLowerCase()
            .includes("already exists");

          const invalidAppointment = error.message.toString().toLowerCase().includes('invalid case number')

          setIsTwoFactorModalOpen(false);
          setIsModalOpen(true);
          setModalTitle(t("oops"));

          if (alreadySubscribed) {
            setModalText(t("already_subscribed"));
          }

          if (invalidAppointment) {
            setModalText(t("invalid_case_no_hearings"));
          }

          if (!alreadySubscribed && !invalidAppointment) {
            setModalText(t("valid_case_number_no_hearings", {
              caseNumber: getValues("caseNumber"),
            }));
          }
        }
      })
      .catch((error) => {
        const alreadySubscribed = error.message
            .toString()
            .toLowerCase()
            .includes("already exists");

        const invalidAppointment = error.message.toString().toLowerCase().includes('invalid case number')

        setIsTwoFactorModalOpen(false);
        setIsModalOpen(true);
        setModalTitle(t("oops"));

        if (alreadySubscribed) {
          setModalText(t("already_subscribed"));
        }

        if (invalidAppointment) {
          setModalText(t("invalid_case_no_hearings"));
        }

        if (!alreadySubscribed && !invalidAppointment) {
          setModalText(t("valid_case_number_no_hearings", {
            caseNumber: getValues("caseNumber"),
          }));
        }
      });
  };

  useEffect(() => {
    if (caseNumberDefIdsQueryData?.defIds?.length === 1) {
      setSelectedDefendant(caseNumberDefIdsQueryData?.defIds[0].defId);
    }
    // if (caseNumberDefIdsQueryData?.defIds?.length === 0) {
      // if no defendants, set defendant id to 00
      // the create appointment api call will be made
      // the api call will return an error and create the appointment inaccurate in database
      // setSelectedDefendant("00");
    // }
    // if there is no def id response it means that the case number used is invalid
    if (
      !caseNumberDefIdsQueryLoading &&
      getValues("caseNumber") &&
      (caseNumberDefIdsQueryData?.defIds === null ||
        caseNumberDefIdsQueryData?.defIds === undefined)
    ) {
      setIsModalOpen(true);
      setModalTitle(t("oops"));
      setModalText(t("generic_creation_error"));
    }
  }, [caseNumberDefIdsQueryData]);

  useEffect(() => {
    if (selectedDefendant) {
      handleSubmit(onSubmit)();
    }
  }, [selectedDefendant]);

  const onSubmit = useCallback(
    (values) => {
      createAppointment({
        variables: {
          language: s18n.language,
          name: values.firstName,
          phoneNumber: values.phoneNumber,
          phoneNumber2: values.phoneNumber2,
          phoneNumber3: values.phoneNumber3,
          caseNumber: values.caseNumber,
          defId: "00",
        },
      }).then((response) => {
        
        if (!selectedDefendant) {
          setIsTwoFactorModalOpen(true);
          setModalTitle(t("one_more_step"));
          setModalText(t("two_factor_modal_text"));
          if (additionalPhoneNumberError) {
            setAdditionalPhoneNumberError(false);
          }
        }
      }).catch((response) => {
        setIsTwoFactorModalOpen(false);
        setIsModalOpen(true);
        setModalTitle(t("oops"));
        setModalText(t("already_subscribed"));
      })

      
    },
    [selectedDefendant]
  );

  const checkTwoFactorPin = () => {
    const caseNumber = getValues("caseNumber");
    checkTwoFactor({
      variables: {
        pin: parseInt(pin),
        caseNumber: caseNumber,
        defId: "00",
      },
    })
      .then((res) => {
        if (res.data.checkTwoFactor.success) {
          setIsTwoFactorModalOpen(false);         
          setIsModalOpen(true);
          setModalTitle(t("success"));
          setModalText(t("all_set_keep_an_eye"));
        }
      })
      .catch(() => {
        setTwoFactorError(t("wrong_pin_error"));
      });
  };

  return (
    <React.Fragment>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}
      >
        <GoogleReCaptcha
          onVerify={() => {
            setSubmitEnabled(true);
          }}
        />
      </GoogleReCaptchaProvider>
      <SPage
        pageType={SPageType.Unauthorized}
        sx={{ padding: 5, width: "100%" }}
      >
        <StyledSTextHeader
          color={"black"}
          fontSize={6}
          mb={20}
          fontWeight="bolder"
        >
          {t("welcome_header_text")}
        </StyledSTextHeader>
        <StyledSTextDescription color={"black"} fontSize={3}>
          {t("welcome_instructions")}
        </StyledSTextDescription>
        <SBox as="form" onSubmit={handleSubmit(onSubmit)}>
          <StyledSFlex justifyContent={"space-between"}>
            <SBox flex={"0 0 49%"}>
              <StyledSInputLabel mt={6} fontSize={4} color={"#000000"}>
                {t("first_name_input_label")}
              </StyledSInputLabel>
              <StyledSInput
                name={"firstName"}
                id={"firstName"}
                placeholder={t("first_name_input_placeholder")}
                ref={register({
                  required: "required_field" as string,
                  pattern: {
                    value: /^[a-zA-Z0-9 '`.-]*$/,
                    message: "error_special_characters_not_allowed" as string,
                  },
                })}
              />
              <SInputError forElementId={"firstName"}>
                {t(localErrors.firstName?.message)}
              </SInputError>
            </SBox>
            <SBox flex={"0 0 49%"}>
              <StyledSInputLabel mt={6} fontSize={4} color={"#000000"}>
                {t("phone_number_input_label")}
              </StyledSInputLabel>
              <Controller
                control={control}
                name="phoneNumber"
                rules={{
                  required: "required_field" as string,
                  pattern: {
                    value: /\+1[0-9]{10}/,
                    message: "invalid_phone_number",
                  },
                }}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <SBox
                    sx={{ position: "relative" }}
                    id={"phone_number-field-wrapper"}
                  >
                    <StyledPhoneNumberPlusOne>+1</StyledPhoneNumberPlusOne>

                    <StyledPhoneNumber
                      id={"phoneNumber"}
                      international={true}
                      placeholder={t("phone_number_input_placeholder")}
                      country={"US"}
                      maxLength={12}
                      value={value}
                      onChange={onChange}
                    />
                    <SInputError forElementId={"phoneNumber"}>
                      {t(localErrors.phoneNumber?.message)}
                    </SInputError>
                  </SBox>
                )}
              />
            </SBox>
          </StyledSFlex>
          <SFlex justifyContent={"space-between"} alignItems={"flex-end"}>
            <SBox flex={"0 0 49%"}>
              <StyledSInputLabel
                fontSize={4}
                color={"#000000"}
                mt={6}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {t("case_number_input_label")}
                <STooltip
                  content={t("case_number_tooltip")}
                  sx={{ marginLeft: "5px" }}
                ></STooltip>
              </StyledSInputLabel>

              <StyledSInput
                name="caseNumber"
                defaultValue={null}
                id="caseNumber"
                type="text"
                placeholder={t("case_number_input_placeholder")}
                ref={register({
                  pattern: {
                    value: /^\S*$/,
                    message: "error_whitespaces_not_allowed" as string,
                  },
                  required: "required_field" as string,
                  validate: caseNumberLength,
                })}
              />
              <SInputError forElementId={"caseNumber"}>
                {t(localErrors.caseNumber?.message)}
              </SInputError>
            </SBox>
            <SBox flex={"0 0 49%"}>
              <SButton
                sx={{
                  width: "100%",
                  backgroundColor: "#9490FF",
                }}
                pr={1}
                pl={1}
                onClick={() =>
                  window.open(
                    "https://www.lacourt.org/criminalcalendar/ui/index.aspx",
                    "_blank"
                  )
                }
                type={"button"}
                id={"case_number_cta"}
              >
                <StyledSTextButton sx={{ lineHeight: "12px" }} fontSize={4}>
                  {t("i_dont_know_my_case_number")}
                </StyledSTextButton>
              </SButton>
            </SBox>
          </SFlex>
          <StyledSInputLabel fontSize={4} color={"#000000"} mt={6}>
            {t("additional_phone_number_label")}
          </StyledSInputLabel>
          <SText fontSize={3} mb={3}>
            {t("additional_phone_number_explanation")}
          </SText>
          <StyledSFlex justifyContent={"space-between"}>
            <SBox flex={"0 0 49%"}>
              <Controller
                control={control}
                name="phoneNumber2"
                rules={{
                  required: false,
                  validate: phoneNumberUnique,
                }}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <StyledSBox
                    sx={{ position: "relative" }}
                    id={"phone_number_2-field-wrapper"}
                    mb={7}
                  >
                    <StyledPhoneNumberPlusOne>+1</StyledPhoneNumberPlusOne>

                    <StyledPhoneNumber
                      id={"phoneNumber2"}
                      international={true}
                      placeholder={t("phone_number_input_placeholder")}
                      country={"US"}
                      maxLength={12}
                      value={value}
                      onChange={onChange}
                    />
                    <SInputError forElementId={"phoneNumber2"}>
                      {t(localErrors.phoneNumber2?.message)}
                    </SInputError>
                  </StyledSBox>
                )}
              />
            </SBox>
            <SBox flex={"0 0 49%"}>
              <Controller
                control={control}
                name="phoneNumber3"
                rules={{
                  required: false,
                }}
                defaultValue={null}
                render={({ onChange, value }) => (
                  <StyledSBox
                    sx={{ position: "relative" }}
                    id={"phone_number_3-field-wrapper"}
                    mb={7}
                  >
                    <StyledPhoneNumberPlusOne>+1</StyledPhoneNumberPlusOne>

                    <StyledPhoneNumber
                      id={"phoneNumber3"}
                      international={true}
                      placeholder={t("phone_number_input_placeholder")}
                      country={"US"}
                      maxLength={12}
                      value={value}
                      onChange={onChange}
                    />
                    <SInputError forElementId={"phoneNumber3"}>
                      {t(localErrors.phoneNumber3?.message)}
                    </SInputError>
                  </StyledSBox>
                )}
              />
            </SBox>
          </StyledSFlex>
          <ConditionalRender
            if={
              !caseNumberDefIdsQueryLoading &&
              caseNumberDefIdsQueryData?.defIds !== null &&
              caseNumberDefIdsQueryData?.defIds.length > 1 &&
              isDefendantSelectorModalOpen &&
              !isTwoFactorModalOpen &&
              !isModalOpen
            }
          >
            <SConfirmModal
              title={t("select_defendant_title")}
              message={""}
              modalRef={ref}
              loading={
                loading || caseNumberDefIdsQueryLoading || twoFactorCheckLoading
              }
              onConfirm={() => {
                const value = getValues("defendantSelect").value;

                if (!value) {
                  setDefendantIdError(t("selected_defendant_error"));
                } else {
                  setDefendantIdError("");
                  setIsDefendantSelectorModalOpen(false);
                  setSelectedDefendant(value);
                }
              }}
              visible={true}
            >
              <SText mb={5}>{t("select_defendant_text")}</SText>
              <Controller
                name="defendantSelect"
                control={control}
                ref={register("defendant")}
                defaultValue={{
                  value: "",
                  label: t("select_defendant_title"),
                }}
                render={(props: any) => {
                  const { onChange, value } = props;
                  return (
                    <Select
                      name="defendant"
                      id="defendant"
                      options={caseNumberDefIdsQueryData?.defIds?.map(
                        (defendant) => {
                          return {
                            value: defendant.defId,
                            label: defendant.name,
                          };
                        }
                      )}
                      onChange={onChange}
                      value={value}
                      placeholder={"defendant placeholder"}
                      components={{ IndicatorSeparator: () => null }}
                      styles={{
                        ...sSelectStyle(theme),
                        container: (provided, state) => ({
                          ...sSelectStyle(theme).container(provided, state),
                          flex: 2,
                        }),
                      }}
                    />
                  );
                }}
              />
              {defendantIdError && (
                <SText color={"inputError"}>{defendantIdError}</SText>
              )}
            </SConfirmModal>
          </ConditionalRender>
          <ConditionalRender if={isTwoFactorModalOpen}>
            <SConfirmModal
              title={modalTitle}
              message={modalText}
              loading={
                loading || caseNumberDefIdsQueryLoading || twoFactorCheckLoading
              }
              modalRef={ref}
              onConfirm={() => checkTwoFactorPin()}
              visible={true}
            >
              <span>{t("two_factor_modal_text")}</span>

              <ReactCodeInput
                inputMode="numeric"
                type="number"
                {...inputStyleProp}
                name="twoFactor"
                onChange={(pin) => {
                  setPin(pin);
                }}
                fields={6}
              />

              {twoFactorError && (
                <SText color={"inputError"}>{twoFactorError}</SText>
              )}
            </SConfirmModal>
          </ConditionalRender>
          <ConditionalRender if={isModalOpen}>
            <SConfirmModal
              title={modalTitle}
              message={modalText}
              modalRef={ref}
              visible={true}
              onCustomButtonClick={() =>
                window.open("https://bit.ly/courtFAQ-22", "_blank")
              }
            ></SConfirmModal>
          </ConditionalRender>
          <SFlex id="acknowledgeCheckboxWrapper">
            <Label>
              <Checkbox
                defaultChecked={null}
                id="acknowledgeCheckbox"
                name="acknowledgeCheckbox"
                color={"#9490FF"}
                ref={register({
                  required: "required_checkbox" as string,
                })}
              />
              <StyledSTextDescription
                fontSize={3}
                color={"black"}
                flex={"0 0 90%"}
              >
                {t("acknowledge_agreement")}
              </StyledSTextDescription>
            </Label>
          </SFlex>
          <SInputError forElementId={"acknowledgeCheckboxWrapper"}>
            {t(localErrors.acknowledgeCheckbox?.message)}
          </SInputError>
          <SFlex mt={5} id="consentCheckboxWrapper">
            <Label>
              <Checkbox
                defaultChecked={null}
                id="consentCheckbox"
                name="consentCheckbox"
                color={"#9490FF"}
                ref={register({
                  required: "required_checkbox" as string,
                })}
              />
              <STextHyperlink
                fontSize={3}
                color={"black"}
                flex={"0 0 90%"}
              >
                {t("consent_agreement")}
              </STextHyperlink>
            </Label>
          </SFlex>
          <SInputError forElementId={"consentCheckboxWrapper"}>
            {t(localErrors.consentCheckbox?.message)}
          </SInputError>
          <SButton
            loading={
              loading || caseNumberDefIdsQueryLoading || twoFactorCheckLoading
            }
            sx={{
              width: "100%",
              backgroundColor: !submitEnabled
                ? theme.colors.gray200
                : "#9490FF",
              marginBottom: 20,
              marginTop: 20,
            }}
            type={"submit"}
            disabled={!submitEnabled}
            id={"submit-button"}
          >
            <StyledSTextSignUp fontSize={4}>
              {t("sign_up_cta")}
            </StyledSTextSignUp>
          </SButton>
        </SBox>
        <SBox
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link
            color={"#9490FF"}
            href={"https://bit.ly/courtFAQ-22"}
            target={"_blank"}
            fontSize={4}
            sx={{
              textAlign: "center",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <strong>{t("need_help_question")}</strong>
          </Link>
          <Link
            color={"#9490FF"}
            href={"https://forms.gle/oHiUXz3gSqwoEWN98"}
            target={"_blank"}
            fontSize={4}
            sx={{
              textAlign: "center",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <strong>{t("report_an_error")}</strong>
          </Link>
        </SBox>
        <STextHyperlink
          fontStyle={"italic"}
          fontSize={2}
          mt={20}
          textAlign={"center"}
        >
          {t("pre_trial_info_text")}
        </STextHyperlink>
      </SPage>
    </React.Fragment>
  );
};

export default SignUpForm;
