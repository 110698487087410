import { TextProps } from 'rebass'
export const sAbsoluteFull = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
}

export const sTextHoverColor = (color: string) => ({
    '&:hover': { color },
})

interface SStyledFontConfig {
    size?: TextProps['fontSize']
    weight?: TextProps['fontWeight']
    color?: TextProps['color']
    hoverColor?: TextProps['color']
}
export const sStyledFont = (config: SStyledFontConfig) => {
    const { size, weight, color, hoverColor } = config

    const retVal = (p) => `
        ${
            size
                ? `font-size: ${
                      typeof size === 'number' ? `${p.theme.fontSizes[size as number]}px` : (size as string)
                  };`
                : ''
        }
        ${weight ? `font-weight: ${p.theme.fontWeights[weight as string]};` : ''}
        ${color ? `color: ${p.theme.colors[color as string]};` : ''}
        ${
            hoverColor // damn i hate this linter sometimes lol
                ? `
        &:hover {
            color: ${p.theme.colors[hoverColor as string]};
        }
        
        `
                : ''
        }
    `

    return retVal
}
