import { useTranslation } from "i18n";
import React from "react";
import Modal from "react-modal";
import { SStyled, useSTheme } from "styles/theme";
import SBox from "./SBox";
import SButton, { SButtonType } from "./SButton";
import SText from "./SText";
import SFlex from "./SFlex";
import ConditionalRender from "utils/conditionalRender";

interface Props {
  title: string;
  message: string | React.ReactNode;
  customButtonText?: string | React.ReactNode;
  onConfirm?: () => void;
  visible: boolean;
  onCancel?: () => void;
  modalRef?: React.RefObject<HTMLInputElement>;
  children?: React.ReactNode;
  confirmMessage?: string;
  onCustomButtonClick?: () => void;
  loading?: boolean
}

const ModalTitle = SStyled(SText)`
    font-size: 24px;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    margin-bottom: ${(p) => p.theme.space[6]}px;
`;

const ModalMessage = SStyled(SText)`
    font-size: ${(p) => p.theme.fontSizes[4]}px;
    color: ${(p) => p.theme.colors.gray400};
    margin-bottom: ${(p) => p.theme.space[8]}px;
    white-space: pre-wrap;
`;

const ContentWrapper = SStyled(SBox)`
    width: 500px;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[0]}) {
        width: 100%;
    }
`;

const SConfirmModal: React.FC<Props> = (props: Props) => {
  const {
    title,
    message,
    onCancel,
    onConfirm,
    visible,
    loading,
    children,
    confirmMessage,
    customButtonText,
    onCustomButtonClick,
    modalRef,
  } = props;

  const { t } = useTranslation("common");
  if (visible) {
    return (
      <Modal
        isOpen={visible}
        overlayClassName={"remove-style"}
        className={"remove-style"}
      >
        <ConditionalRender if={!children}>
          <ContentWrapper px={[7, 9, 9]} py={[7, 8, 8]} ref={modalRef}>
            <ModalTitle>{title}</ModalTitle>
            <ModalMessage>{message}</ModalMessage>

            <SFlex flexDirection="row">
              <ConditionalRender if={onCancel}>
                <SButton
                  mr={5}
                  width={"100%"}
                  buttonType={SButtonType.Cancel}
                  onClick={onCancel}
                  flex="1 1 0"
                >
                  {t("common:cancel")}
                </SButton>
              </ConditionalRender>
              <ConditionalRender if={onConfirm}>
                <SButton
                  width={"100%"}
                  loading={loading}
                  onClick={onConfirm}
                  flex="1 1 0"
                  sx={{
                    width: "100%",
                    backgroundColor: "#9490FF",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  <SText sx={{ color: "white" }}>
                    {confirmMessage ? confirmMessage : t("common:confirm")}
                  </SText>
                </SButton>
              </ConditionalRender>
              <ConditionalRender if={customButtonText}>
                <SButton
                  sx={{
                    width: "100%",
                    backgroundColor: "#9490FF",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                  width={"100%"}
                  flex="1 1 0"
                  onClick={onCustomButtonClick}
                >
                  {customButtonText}
                </SButton>
              </ConditionalRender>
            </SFlex>
          </ContentWrapper>
        </ConditionalRender>
        <ConditionalRender if={children}>
          <ContentWrapper px={[7, 9, 9]} py={[7, 8, 8]} ref={modalRef}>
            <ModalTitle>{title}</ModalTitle>
            {children}
            <SFlex flexDirection="row">
              <ConditionalRender if={onCancel}>
                <SButton
                  mr={5}
                  width={"100%"}
                  buttonType={SButtonType.Cancel}
                  onClick={onCancel}
                  flex="1 1 0"
                >
                  {t("common:cancel")}
                </SButton>
              </ConditionalRender>
              <ConditionalRender if={onConfirm}>
                <SButton
                  buttonType={SButtonType.Outline}
                  loading={loading}
                  width={"100%"}
                  onClick={onConfirm}
                  sx={{
                    width: "100%",
                    backgroundColor: "#9490FF",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                  flex="1 1 0"
                >
                  <SText sx={{ color: "white" }}>
                    {confirmMessage ? confirmMessage : t("common:confirm")}
                  </SText>
                </SButton>
              </ConditionalRender>
            </SFlex>
          </ContentWrapper>
        </ConditionalRender>
      </Modal>
    );
  } else {
    return null;
  }
};

export default SConfirmModal;
