import React, { forwardRef } from 'react'
import { Text, TextProps } from 'rebass'
import Truncate from 'react-truncate'

interface Props extends TextProps {
    maxLines?: number
}


const STextHyperlink = forwardRef<any, Props>((props: Props, ref) => {
    const { maxLines, children, ...otherProps } = props

    const content = maxLines ? (
        <Truncate lines={maxLines} trimWhitespace={true} dangerouslySetInnerHTML={{
            __html: `${children}`,
          }}/>
    ) : (
        children
    )

    return (
        <Text ref={ref} {...otherProps}  dangerouslySetInnerHTML={{
            __html: `${children}`,
          }}/>
            
    )
})

STextHyperlink.displayName = 'STextHyperlink'

export default STextHyperlink
