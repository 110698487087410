import React from 'react'

import { LabelProps, Label } from '@rebass/forms'

type Props = LabelProps

const SInputLabel = React.forwardRef((props: Props, ref) => {
    const { sx = {}, ...otherProps} = props

    return (
        <Label
            sx={{
                mb: 2,
                color: 'inputLabel',
                fontWeight: 'semiBold',
                fontSize: 1,
                ...sx,
            }}
            ref={ref}
            {...otherProps}
        />
    )
})

SInputLabel.displayName = 'SInputLabel'

export default SInputLabel
